import { Tag } from "antd";
import React from "react";
import { connect } from "react-redux";

const CountUnread = ({ menuKey, counts }) => {
  if (!menuKey || !counts?.[menuKey]?.count || counts?.[menuKey]?.count === 0) {
    return null;
  }
  return (
    <Tag
      color="#ff6b72"
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        borderRadius: "16px",
        lineHeight: "20px",
        height: "20px",
      }}
    >
      {counts[menuKey].count}
    </Tag>
  );
};

const mapStateToProps = ({ counts }) => {
  return { counts };
};

export default connect(mapStateToProps)(CountUnread);
