import {
  ERROR_TINY_KEYS,
  GET_TINY_KEYS,
  SET_TINY_KEYS,
} from "../constants/TinyKeys";

export const getTinyKeys = () => {
  return {
    type: GET_TINY_KEYS,
  };
};

export const setTinyKeys = (keys) => {
  return {
    type: SET_TINY_KEYS,
    payload: keys,
  };
};

export const errorTinyKeys = (error) => {
  return {
    type: ERROR_TINY_KEYS,
    payload: error,
  };
};
