import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import {
  GET_COUNT_COMMENT,
  GET_COUNT_FEEDBACK,
  GET_COUNT_REVIEW,
} from "../constants/Counts";
import {
  errorCountComment,
  errorCountFeedback,
  errorCountReview,
  setCountComment,
  setCountFeedback,
  setCountReview,
} from "../actions/Counts";
import apiService from "../../services/ApiService";

export function* getCountCommentSaga() {
  yield takeEvery(GET_COUNT_COMMENT, function* () {
    try {
      const response = yield call(apiService.getCommentUnreadCount);
      if (response?.status === 200) {
        yield put(setCountComment(response?.data));
      } else {
        throw new Error(
          "Ошибка загрузки количества непрочитанных комментариев"
        );
      }
    } catch (err) {
      yield put(errorCountComment(err?.massage || err));
    }
  });
}

export function* getCountReviewSaga() {
  yield takeEvery(GET_COUNT_REVIEW, function* () {
    try {
      const response = yield call(apiService.getReviewUnreadCount);
      if (response?.status === 200) {
        yield put(setCountReview(response?.data));
      } else {
        throw new Error("Ошибка загрузки количества непрочитанных отзывов");
      }
    } catch (err) {
      yield put(errorCountReview(err?.massage || err));
    }
  });
}

export function* getCountFeedbackSaga() {
  yield takeEvery(GET_COUNT_FEEDBACK, function* () {
    try {
      const response = yield call(apiService.getFeedbackUnreadCount);
      if (response?.status === 200) {
        yield put(setCountFeedback(response?.data));
      } else {
        throw new Error("Ошибка загрузки количества непрочитанных обращений");
      }
    } catch (err) {
      yield put(errorCountFeedback(err?.massage || err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getCountCommentSaga)]);
  yield all([fork(getCountReviewSaga)]);
  yield all([fork(getCountFeedbackSaga)]);
}
