import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/dashboards`}
          component={lazy(() => import(`./dashboards`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/components`}
          component={lazy(() => import(`./components`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/pages`}
          component={lazy(() => import(`./pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/maps`}
          component={lazy(() => import(`./maps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/docs`}
          component={lazy(() => import(`./docs`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/site/proxy`}
          component={lazy(() => import(`./admin-views/proxySites`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/goal/group`}
          component={lazy(() => import(`./admin-views/goalGroups`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/countries`}
          component={lazy(() => import(`./admin-views/countries`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/goal`}
          component={lazy(() => import(`./admin-views/goals`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/site`}
          component={lazy(() => import(`./admin-views/sites`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/page`}
          component={lazy(() => import(`./admin-views/pages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/users`}
          component={lazy(() => import(`./admin-views/users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/translation`}
          component={lazy(() => import(`./admin-views/translations`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/system`}
          component={lazy(() => import(`./admin-views/system`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/blog`}
          component={lazy(() => import(`./admin-views/blog`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/status`}
          component={lazy(() => import(`./admin-views/siteStatus`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/currency`}
          component={lazy(() => import(`./admin-views/currency`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/rent`}
          component={lazy(() => import(`./admin-views/rent`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/review`}
          component={lazy(() => import(`./admin-views/review`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/complaints`}
          component={lazy(() => import(`./admin-views/complaints`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/email`}
          component={lazy(() => import(`./admin-views/emailTemplate`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/comments`}
          component={lazy(() => import(`./admin-views/comments`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/feedback`}
          component={lazy(() => import(`./admin-views/feedback`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promocode`}
          component={lazy(() => import(`./admin-views/promocode`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/user-choice`}
          component={lazy(() => import(`./admin-views/userChoice`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/faq`}
          component={lazy(() => import(`./admin-views/faq`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/seo-block`}
          component={lazy(() => import(`./admin-views/seoBlock`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/statistic`}
          component={lazy(() => import(`./admin-views/siteStatistic`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/proxy/type`}
          component={lazy(() => import(`./admin-views/proxyTypes`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/redirect`}
          component={lazy(() => import(`./admin-views/redirect`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/meta`}
          component={lazy(() => import(`./admin-views/metaPages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/seo`}
          component={lazy(() => import(`./admin-views/seo`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sitemap`}
          component={lazy(() => import(`./admin-views/sitemap`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/cache`}
          component={lazy(() => import(`./admin-views/cache`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/captcha`}
          component={lazy(() => import(`./admin-views/captcha`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/social`}
          component={lazy(() => import(`./admin-views/social`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/utils`}
          component={lazy(() => import(`./admin-views/utils`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/main/blocks`}
          component={lazy(() => import(`./admin-views/mainBlockList`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/front/error/report`}
          component={lazy(() => import(`./admin-views/frontErrorReport`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/externalStorage`}
          component={lazy(() => import(`./admin-views/externalStorage`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/validator/email`}
          component={lazy(() => import(`./admin-views/emailValidator`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/tiny-keys/`}
          component={lazy(() => import(`./admin-views/TinyKeys`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/tools/`}
          component={lazy(() => import(`./admin-views/ToolsSettings`))}
        />
        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/page/list`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
