import store from "./redux/store";
import Views from "./views";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Switch } from "react-router-dom";

function App() {
  // console.log("APP MODE", process.env.REACT_APP_MODE);
  // console.log("APP URL", process.env.REACT_APP_URL);
  // console.log("APP ADMIN URL", process.env.REACT_APP_ADMIN_URL);

  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </Provider>
    </div>
  );
}

export default App;
