import { AUTH_TOKEN, AUTH_USER } from "../redux/constants/Auth";
import axios from "axios";

axios.interceptors.response.use(
  function (response) {
    try {
      if (response.data.slice(0, 15) === "<!doctype html>") {
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_USER);
        window.location.reload();

        return null;
      } else {
        return response;
      }
    } catch (e) {
      return response;
    }
  },
  function (error) {
    try {
      if (error.config.url === "/api/login") {
        return error;
      }
    } catch (e) {
      console.error("Login error", e);
    }
    if (typeof error.response === undefined || error.response.status === 401) {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(AUTH_USER);
      window.location.reload();

      return null;
    } else {
      return error;
    }
  }
);

const apiService = {};

apiService.getValidator = async function () {
  return await axios.get("/api/admin/email/validator");
};

apiService.getValidatorCredits = async function () {
  return await axios.get("/api/admin/email/validator/credits");
};

apiService.getValidatorHistory = async function () {
  return await axios.get("/api/admin/email/validator/history");
};

apiService.setValidator = async function (data) {
  return await axios.put("/api/admin/email/validator", data);
};

apiService.logout = async function () {
  return await axios
    .get("/api/logout")
    .then(() => true)
    .catch(() => false);
};

apiService.login = async function (username, password) {
  return await axios
    .post("/api/login", null, {
      params: { username, password, "remember-me": true },
    })
    .then((req) => {
      try {
        return req.status === 200;
      } catch (e) {
        return false;
      }
    })
    .catch(() => {
      return false;
    });
};

apiService.getAdminUser = function () {
  return axios.get("/api/users/adminUser");
};

apiService.createDefaultUser = function (email, password) {
  return axios.post("/api/users/createDefaultUser", { email, password });
};

apiService.usersGetAuthUser = async function () {
  return await axios
    .get("/api/users/authUser")
    .then((res) => res.data)
    .catch(() => null);
};

apiService.usersGetAuthUserAsync = async function () {
  return await axios.get("/api/users/authUser");
};

apiService.usersGetAll = function (page, size, sort, search) {
  return axios.get("/api/users", {
    params: { page: page - 1, size, sort, search },
  });
};

apiService.usersGetAllNoPage = function () {
  return axios.get("/api/users/no-page");
};

apiService.userProfile = function (userId) {
  return axios.get("/api/users/profile", {
    params: { id: userId },
  });
};

apiService.usersGetUser = function (id) {
  return axios.get("/api/users/entity", { params: { id } });
};

apiService.usersSetNewPassword = function (newPassword) {
  return axios.post("/api/users/password", newPassword);
};

apiService.usersCreate = function (user) {
  return axios.post("/api/users/createUser", {
    name: user.name,
    email: user.email,
    password: user.password,
    roles: [],
  });
};

apiService.usersDelete = function (id) {
  return axios.delete("/api/users", { params: { id } });
};

apiService.usersSaveProfile = function (userProfile) {
  return axios.post("/api/users/profile", userProfile);
};

apiService.getCommentUnreadCount = function () {
  return axios.get("/api/admin/site/comments/unread/count");
};

apiService.getReviewUnreadCount = function () {
  return axios.get("/api/admin/site/review/unread/count");
};

apiService.getFeedbackUnreadCount = function () {
  return axios.get("/api/admin/site/feedback/unreadCount");
};

apiService.readCommentById = function (id) {
  return axios.get("/api/admin/site/comments/read", { params: { id } });
};

apiService.readAllComments = function () {
  return axios.get("/api/admin/site/comments/read/all");
};

apiService.readReviewById = function (id) {
  return axios.get("/api/admin/site/review/read", { params: { id } });
};

apiService.readAllReviews = function () {
  return axios.get("/api/admin/site/review/read/all");
};

apiService.readFeedbackById = function (feedbackId) {
  return axios.put(
    "/api/admin/site/feedback/read",
    {},
    { params: { feedbackId } }
  );
};

apiService.readAllFeedbacks = function () {
  return axios.put("/api/admin/site/feedback/readAll");
};

apiService.translationGetAll = function () {
  return axios.get("/api/translation/all");
};

apiService.translationGet = function (id) {
  return axios.get("/api/translation", { params: { id } });
};

apiService.translationCreate = function (
  name,
  languageCode,
  languageNameInternational,
  languageNameOriginal,
  rfc4646
) {
  return axios.put("/api/translation", {
    name,
    languageCode,
    languageNameInternational,
    languageNameOriginal,
    rfc4646,
  });
};

apiService.translationSave = function (
  id,
  name,
  languageCode,
  languageNameInternational,
  languageNameOriginal,
  rfc4646
) {
  return axios.post("/api/translation", {
    id,
    name,
    languageCode,
    languageNameInternational,
    languageNameOriginal,
    rfc4646,
  });
};

apiService.deleteTranslationById = function (id) {
  return axios.delete("/api/translation", {
    params: { id },
  });
};

apiService.pageGetAll = function () {
  return axios.get("/api/page/all");
};

apiService.pageGetAllWithFaq = function () {
  return axios.get("/api/page/byContainsFaq", {
    params: { containsFaq: true },
  });
};

apiService.pageGet = function (id) {
  return axios.get("/api/page", { params: { id } });
};

apiService.pageCreate = function (
  name,
  tag,
  pathname,
  sitemap,
  containsFaq = false
) {
  return axios.put("/api/page", { name, tag, pathname, sitemap, containsFaq });
};

apiService.deletePageById = function (id) {
  return axios.delete("/api/page", {
    params: { id },
  });
};

apiService.pageSave = function (
  id,
  name,
  tag,
  pathname,
  imageUrl,
  sitemap,
  containsFaq = false
) {
  return axios.post("/api/page", {
    id,
    name,
    tag,
    pathname,
    imageUrl,
    sitemap,
    containsFaq,
  });
};

apiService.pageContentGet = function (pageId, translationId) {
  return axios.get("/api/page/content", {
    params: { pageId, translationId },
  });
};

apiService.pageFrontContentGet = function (tag, languageCode) {
  return axios.get("/api/page/front/content", {
    params: { tag, languageCode },
  });
};

apiService.pageContentSave = function (params) {
  return axios.post("/api/page/content", params);
};

apiService.siteGetAll = function () {
  return axios.get("/api/site/get/all");
};

apiService.siteGetAllActive = function () {
  return axios.get("/api/site/get/allActive");
};

apiService.siteTopGetAll = function () {
  return axios.get("/api/site/top");
};

apiService.siteTopAdd = function (siteId) {
  return axios.post("/api/site/top/create", { siteId });
};

apiService.siteTopSave = function (siteTop) {
  return axios.post("/api/site/top", { siteTop });
};

apiService.siteTopDelete = function (id) {
  return axios.delete("/api/site/top", { params: { id } });
};

apiService.siteProxyGetAll = function () {
  return axios.get("/api/site/proxy/get/all");
};

apiService.getSitesByFilter = function (req) {
  return axios.post("/api/admin/site/filter", req);
};

apiService.siteDelete = function (id) {
  return axios.delete("/api/site", { params: { id } });
};

apiService.siteProxyDelete = function (id) {
  return axios.delete("/api/site/proxy", { params: { id } });
};

apiService.siteProxyDeleteImage = function (id) {
  return axios.delete("/api/site/proxy/image", { params: { id } });
};

apiService.siteProxyDeleteFavicon = function (siteId) {
  return axios.delete("/api/admin/site/favicon", { params: { siteId } });
};

apiService.adminSiteProxySave = function (body) {
  return axios.post("/api/admin/site/proxy", body);
};

// apiService.adminDeleteProxyTypeDataFromSite = function (id) {
//   return axios.delete(`/api/admin/site/proxy/typeProps/${id}`);
// };

apiService.siteProxySave = function (
  id,
  name,
  email,
  description,
  link,
  freeTest,
  individual,
  replacementPossibility,
  countries,
  proxyType,
  purposeUse,
  priceMax,
  minRentPeriod,
  clientNoAuth,
  clientLoginAuth,
  clientIPAuth,
  refunds,
  affiliateProgram,
  userId,
  promoCode,
  topSite,
  topIndex,
  age,
  affiliatePercent,
  priceMin,
  currencyId,
  statusId,
  socialNetworks
) {
  return axios.post("/api/admin/site/proxy", {
    id,
    name,
    email,
    description,
    link,
    freeTest,
    individual,
    replacementPossibility,
    countries,
    proxyType,
    purposeUse,
    priceMax,
    minRentPeriod,
    clientNoAuth,
    clientLoginAuth,
    clientIPAuth,
    refunds,
    affiliateProgram,
    userId,
    promoCode,
    topSite,
    topIndex,
    age,
    affiliatePercent,
    priceMin,
    currencyId,
    statusId,
    socialNetworks,
  });
};

apiService.siteProxyGet = function (id) {
  return axios.get("/api/admin/site/proxy", { params: { id } });
};

apiService.countriesGetAll = function () {
  return axios.get("/api/country/admin/all");
};

apiService.countriesGetAllPage = function (page, size) {
  return axios.get("/api/admin/page/countries", {
    params: { page: page - 1, size },
  });
};

// apiService.goalGetAll = function () {
//     return axios.get('/api/goal/front/all')
// }

apiService.countriesCreate = function (params) {
  return axios.put("/api/admin/countries/entity", params);
};

apiService.countriesDelete = function (id) {
  return axios.delete("/api/admin/countries", { params: { id } });
};

apiService.countriesGetEntity = function (id) {
  return axios.get("/api/admin/countries/entity", { params: { id } });
};

apiService.countriesSave = function (entity) {
  return axios.post("/api/admin/countries/entity", entity);
};

apiService.goalGetAll = function () {
  return axios.get("/api/goal/all");
};

apiService.goalGet = function (id) {
  return axios.get("/api/goal", { params: { id } });
};

apiService.goalDelete = function (id) {
  return axios.delete("/api/goal", { params: { id } });
};

apiService.goalSave = function (id, name, tag, groupId, translation) {
  return axios.post("/api/goal", { id, name, tag, groupId, translation });
};

apiService.goalCreate = function (name, tag, translation) {
  return axios.post("/api/goal/create", { name, tag, translation });
};

apiService.goalGroupGetAll = function () {
  return axios.get("/api/goal/group/all");
};

apiService.goalGroupGet = function (id) {
  return axios.get("/api/goal/group", { params: { id } });
};

apiService.goalGroupDelete = function (id) {
  return axios.delete("/api/goal/group", { params: { id } });
};

apiService.goalGroupSave = function (id, name, tag) {
  return axios.post("/api/goal/group", { id, name, tag });
};

apiService.goalGroupCreate = function (name, tag) {
  return axios.put("/api/goal/group", { name, tag });
};

apiService.systemPropertiesGet = function () {
  return axios.get("/api/system/properties");
};

apiService.systemPropertiesSave = function (
  siteTitle,
  siteDescription,
  globalReviewApproval
) {
  return axios.post("/api/system/properties", {
    siteTitle,
    siteDescription,
    globalReviewApproval,
  });
};

apiService.draftBlogPost = function (groupId, languageCode) {
  return axios.post(
    "/api/admin/site/blog/draft",
    { groupId, languageCode },
    { headers: { "Content-Type": "application/json" } }
  );
};

apiService.updateBlogPost = function (
  groupId,
  id,
  title,
  description,
  articleBody,
  category,
  popular,
  translationId,
  alias,
  status,
  tags,
  anchors
) {
  return axios.put("/api/admin/site/blog/update", {
    groupId,
    id,
    title,
    description,
    articleBody,
    category,
    popular,
    translationId,
    alias,
    status,
    tags,
    anchors,
  });
};

apiService.getAllBlogPosts = function (page, size, category, status, alias) {
  return axios.get("/api/admin/site/blog", {
    params: { page, size, category, status, alias },
  });
};

apiService.getBlogsByFilter = function (req) {
  return axios.post("/api/admin/site/blog/filter", req);
};

apiService.getBlogByLang = function (groupId, languageCode) {
  return axios.get("/api/admin/site/blog/view-article", {
    params: { groupId, languageCode },
  });
};

apiService.deleteBlogPostById = function (articleId) {
  return axios.delete("/api/admin/site/blog/delete", {
    params: { articleId },
  });
};

apiService.uploadBlogFile = function (params) {
  return axios.post("/api/admin/site/blog/file", params);
};

apiService.deleteBlogFileById = function (fileId) {
  return axios.delete("/api/admin/site/file/delete", {
    params: { fileId },
  });
};

apiService.getCurrencyAll = function () {
  return axios.get("/api/admin/currency/get/all");
};

apiService.createCurrency = function (req) {
  return axios.post("/api/admin/currency/create", req);
};

apiService.getCurrencyById = function (id) {
  return axios.get("/api/admin/currency/get/id", {
    params: { id },
  });
};

apiService.updateCurrency = function (req) {
  return axios.put("/api/admin/currency/update", req);
};

apiService.deleteCurrencyById = function (id) {
  return axios.delete("/api/admin/currency/delete", {
    params: { id },
  });
};

apiService.getSiteStatusAll = function () {
  return axios.get("/api/admin/status/list");
};

apiService.createSiteStatus = function (req) {
  return axios.post("/api/admin/status/create", req);
};

apiService.getSiteStatusByType = function (type) {
  return axios.get("/api/admin/status/get", {
    params: { type },
  });
};

apiService.updateSiteStatus = function (req) {
  return axios.put("/api/admin/status/update", req);
};

apiService.getSiteStatusTranslation = function (siteStatusId) {
  return axios.get("/api/admin/siteStatusTranslation/bySiteStatus", {
    params: { siteStatusId },
  });
};

apiService.updateSiteStatusTranslation = function (data) {
  return axios.post("/api/admin/siteStatusTranslation", data);
};

apiService.deleteSiteStatusById = function (id) {
  return axios.delete("/api/admin/status/delete", {
    params: { id },
  });
};

apiService.getRentAll = function () {
  return axios.get("/api/admin/rent/get/all");
};

apiService.createRent = function (req) {
  return axios.post("/api/admin/rent/create", req);
};

apiService.getRentById = function (id) {
  return axios.get("/api/admin/rent/get/id", {
    params: { id },
  });
};

apiService.updateRent = function (req) {
  return axios.put("/api/admin/rent/update", req);
};

apiService.deleteRentById = function (id) {
  return axios.delete("/api/admin/rent/delete", {
    params: { id },
  });
};

apiService.getReviewsByFilter = function (params) {
  return axios.post("/api/admin/site/review/filter", params);
};

apiService.approveReview = function (req) {
  return axios.put("/api/admin/site/reviews/approveReview", req);
};

apiService.moderateReviewsMass = function (approveIds) {
  return axios.post("/api/admin/site/review/moderate/mass", approveIds);
};

apiService.deleteReviewById = function (reviewId) {
  return axios.delete("/api/admin/site/review/delete", {
    params: { reviewId },
  });
};

apiService.deleteReviewsMass = function (ids) {
  return axios.delete("/api/admin/site/reviews/delete/mass", { data: ids });
};

apiService.editReview = function (req) {
  return axios.put("/api/admin/site/reviews/edit", req);
};

apiService.getEmailTemplateAll = function (req) {
  return axios.post("/api/admin/email/template/all", req);
};

apiService.createEmailTemplate = function (req) {
  return axios.post("/api/admin/email/template", req);
};

apiService.getEmailTemplateById = function (id) {
  return axios.get("/api/admin/email/template", {
    params: { id },
  });
};

apiService.updateEmailTemplate = function (req) {
  return axios.put("/api/admin/email/template", req);
};

apiService.deleteEmailTemplateById = function (id) {
  return axios.delete("/api/admin/email/template", {
    params: { id },
  });
};

apiService.getEmailTemplateTypes = function () {
  return axios.get("/api/admin/email/template/types");
};

apiService.getComplaintsByFilter = function (
  page,
  size,
  sort,
  email,
  siteName
) {
  return axios.post("/api/admin/site/complaints/filter", {
    page,
    size,
    sort,
    email,
    siteName,
  });
};

apiService.approveComplaint = function (req) {
  return axios.put(
    "/api/admin/site/complaints/approveComplaint",
    {},
    { params: req }
  );
};

apiService.approveComplaintsMass = function (approveIds) {
  return axios.post("/api/admin/site/complaints/approve/mass", approveIds);
};

apiService.deleteComplaintById = function (complaintId) {
  return axios.delete("/api/admin/site/complaints/delete", {
    params: { complaintId },
  });
};

apiService.deleteComplaintsMass = function (ids) {
  return axios.delete("/api/admin/site/complaints/delete/mass", { data: ids });
};

apiService.editComplaint = function (req) {
  return axios.put("/api/admin/site/complaints/update", req);
};

apiService.getCommentsByFilter = function (page, size, sort, email, ownerName) {
  return axios.post("/api/admin/site/comments/list", {
    page,
    size,
    sort,
    email,
    ownerName,
  });
};

apiService.updateComments = function (req) {
  return axios.put("/api/admin/site/comments/update", req);
};

apiService.visibilityCommentsMass = function (visibilityIds) {
  return axios.post("/api/admin/comments/visibility/mass", visibilityIds);
};

apiService.deleteCommentById = function (commentId) {
  return axios.delete("/api/admin/site/comments/delete", {
    params: { commentId },
  });
};

apiService.deleteCommentsMass = function (ids) {
  return axios.delete("/api/admin/site/comments/delete/mass", { data: ids });
};

apiService.getFeedbackAll = function (page, size, isRead) {
  return axios.get("/api/admin/site/feedback", {
    params: { page, size, isRead },
  });
};

apiService.deleteFeedbackById = function (feedbackId) {
  return axios.delete("/api/admin/site/feedback/delete", {
    params: { feedbackId },
  });
};

apiService.getPromocodeAll = function (page, size, sort, siteName) {
  return axios.post("/api/admin/promo/page", {
    page,
    size,
    sort,
    siteName,
  });
};

apiService.updatePromocode = function (req) {
  return axios.put("/api/admin/promocode", req);
};

apiService.createPromocode = function (req) {
  return axios.post("/api/admin/promocode", req);
};

apiService.getPromocodeById = function (id) {
  return axios.get("/api/admin/promocode", {
    params: { id },
  });
};

apiService.deletePromocodeById = function (id) {
  return axios.delete("/api/admin/promocode", {
    params: { id },
  });
};

apiService.getProfanityAll = function (req) {
  return axios.post("/api/admin/profanity/page", req);
};

apiService.createProfanity = function (word) {
  return axios.post("/api/admin/profanity", word, {
    headers: { "Content-Type": "text/plain" },
  });
};

apiService.updateProfanity = function (req) {
  return axios.post("/api/admin/profanity/update", req);
};

apiService.deleteProfanityById = function (id) {
  return axios.delete("/api/admin/profanity/delete", {
    params: { id },
  });
};

apiService.deleteProfanityMass = function (ids) {
  return axios.delete("/api/admin/profanity/delete/mass", { data: ids });
};

apiService.getSiteStatistic = function () {
  return axios.get("/api/admin/site/statistic/update");
};

apiService.updateSiteStatistic = function (req) {
  return axios.put("/api/admin/site/statistic", req);
};

apiService.getProxyTypeAll = function (page, size, sort) {
  return axios.post("/api/admin/proxy/type/page", { page, size, sort });
};

apiService.sortProxyTypeMass = function (sorts) {
  return axios.put("/api/admin/proxy/types", sorts);
};

apiService.getProxyTypesAll = function () {
  return axios.get("/api/admin/proxy/type/all");
};

apiService.createProxyType = function (params) {
  return axios.post("/api/admin/proxy/type", params);
};

apiService.getProxyTypeById = function (id) {
  return axios.get("/api/admin/proxy/type", {
    params: { id },
  });
};

apiService.updateProxyType = function (params) {
  return axios.put("/api/admin/proxy/type", params);
};

apiService.deleteProxyTypeById = function (id) {
  return axios.delete("/api/admin/proxy/type", {
    params: { id },
  });
};

apiService.getMetaPagesByFilter = function (req) {
  return axios.post("/api/admin/metapage/filter", req);
};

apiService.updateMetaPage = function (req) {
  return axios.post("/api/admin/metapage/update", req);
};

apiService.getMetaPageById = function (id) {
  return axios.get("/api/admin/metapage/get", {
    params: { id },
  });
};

apiService.deleteMetaPageById = function (id) {
  return axios.delete("/api/admin/metapage/delete", {
    params: { id },
  });
};

apiService.getSiteMap = function () {
  return axios.get("/api/custom/sitemap");
};

apiService.saveSiteMap = function (str) {
  return axios.post("/api/admin/custom/sitemap", str, {
    headers: { "Content-Type": "text/xml" },
  });
};

apiService.getRobotsTxt = function () {
  return axios.get("/api/admin/property/robots");
};

apiService.saveRobotsTxt = function (str) {
  return axios.post("/api/admin/property/robots", str, {
    headers: { "Content-Type": "text/plain" },
  });
};

apiService.getCustomHtml = function () {
  return axios.get("/api/admin/property/html");
};

apiService.saveCustomHtml = function (str) {
  return axios.put("/api/admin/property/html", str, {
    headers: { "Content-Type": "text/plain" },
  });
};

apiService.getSitemapStatus = function () {
  return axios.get("/api/admin/sitemap/status");
};

apiService.updateSitemap = function (tags) {
  return axios.post("/api/admin/sitemap/update", tags);
};

apiService.getSettingSitemapGenerator = function () {
  return axios.get("/api/admin/sitemap/generator");
};

apiService.updateSettingSitemapGenerator = function (req) {
  return axios.put("/api/admin/sitemap/generator", req);
};

apiService.deleteSitemap = function (tags) {
  return axios.delete("/api/admin/sitemap/delete", { data: tags });
};

apiService.getCacheAll = function () {
  return axios.get("/api/admin/cache/all");
};

apiService.clearCache = function (cachesNames) {
  return axios.post("/api/admin/cache/clear", cachesNames);
};

apiService.clearCacheAll = function () {
  return axios.get("/api/admin/cache/clearall");
};

apiService.clearCacheSSR = function () {
  return axios.get("/api/admin/cache/ssr/clear");
};

apiService.getSiteValidatorHistoryById = function (id) {
  return axios.get("/api/admin/site/validator/history", {
    params: { id },
  });
};

apiService.getStatusSiteValidator = function () {
  return axios.get("/api/admin/site/validator/status");
};

apiService.getConfigSiteValidator = function () {
  return axios.get("/api/admin/site/validator");
};

apiService.updateConfigSiteValidator = function (req) {
  return axios.put("/api/admin/site/validator", req);
};

apiService.manualSiteValidation = function (req) {
  return axios.post("/api/admin/site/validation", req);
};

apiService.restartValidator = function (req) {
  return axios.post("/api/front/site/validation", req);
};

apiService.getStatusSitesWhois = function () {
  return axios.get("/api/admin/site/whois/status");
};

apiService.getConfigSiteWhois = function () {
  return axios.get("/api/admin/site/whois");
};

apiService.updateConfigSiteWhois = function (req) {
  return axios.put("/api/admin/site/whois", req);
};

apiService.getSiteWhoisHistoryById = function (id) {
  return axios.get("/api/admin/site/whois/history", {
    params: { id },
  });
};

apiService.getSiteRedirectAll = function () {
  return axios.get("/api/admin/site/redirect/all");
};

apiService.getSiteRedirectById = function (id) {
  return axios.get("/api/admin/site/redirect", {
    params: { id },
  });
};

apiService.updateSiteRedirect = function (req) {
  return axios.put("/api/admin/site/redirect", req);
};

apiService.deleteSiteRedirectById = function (id) {
  return axios.delete("/api/admin/site/redirect", {
    params: { id },
  });
};

apiService.getConfigCaptcha = function () {
  return axios.get("/api/admin/captcha");
};

apiService.updateConfigCaptcha = function (req) {
  return axios.put("/api/admin/captcha", req);
};

apiService.getConfigStorageS3 = function () {
  return axios.get("/api/admin/cloud");
};

apiService.updateConfigStorageS3 = function (body) {
  return axios.put("/api/admin/cloud", body);
};

apiService.getSocialAll = function () {
  return axios.get("/api/admin/social");
};

apiService.sortSocialMass = function (sorts) {
  return axios.put("/api/admin/social/items", sorts);
};

apiService.getSocialById = function (id) {
  return axios.get("/api/admin/social/item", {
    params: { id },
  });
};

apiService.updateSocial = function (req) {
  return axios.put("/api/admin/social", req);
};

apiService.deleteSocialById = function (id) {
  return axios.delete("/api/admin/social", {
    params: { id },
  });
};

apiService.getStatusTranslator = function () {
  return axios.get("/api/admin/util/translator/status");
};

apiService.getConfigTranslator = function () {
  return axios.get("/api/admin/util/translator");
};

apiService.updateConfigTranslator = function (req) {
  return axios.put("/api/admin/util/translator", req);
};

apiService.manualStartTranslator = function () {
  return axios.get("/api/admin/util/translator/status");
};

apiService.getMainBlockList = function () {
  return axios.get("/api/admin/property/main/block/list");
};

apiService.updateMainBlockList = function (req) {
  return axios.put("/api/admin/property/main/block/list", req);
};

apiService.getMainBlockListAllowed = function () {
  return axios.get("/api/admin/property/main/block/list/allowed");
};

apiService.getFrontErrorReportPage = function (page, size) {
  return axios.get("/api/admin/front/error/report/list", {
    params: { page, size },
  });
};

apiService.getFrontErrorReportEntity = function (id) {
  return axios.get("/api/admin/front/error/report", { params: { id } });
};

apiService.faqCreate = function (params) {
  return axios.post("/api/admin/faqs", params);
};

apiService.faqEdit = function (params) {
  return axios.put("/api/admin/faqs", params);
};

apiService.faqGetAll = function (pageId, translationId) {
  return axios.post("/api/admin/faqs/all", {
    pageId,
    translationId,
  });
};

apiService.faqChangePosition = function (params) {
  return axios.post("/api/admin/faqs/indices", params);
};

apiService.deleteFaqById = function (id) {
  return axios.delete(`/api/admin/faqs/${id}`);
};

apiService.faqGetOne = function (id) {
  return axios.get(`/api/admin/faqs/${id}`);
};

// user choice
apiService.userChoiceGetAll = function () {
  return axios.post("/api/admin/userChoices/all");
};

apiService.reCalculateUserChoice = function () {
  return axios.post("/api/visits/count?updateUserChoice=true");
};

apiService.userChoiceCreate = function (params) {
  return axios.post("/api/admin/userChoices", params);
};

apiService.userChoiceUpdate = function (params) {
  return axios.put(`/api/admin/userChoices`, params);
};

apiService.userChoiceGetById = function (id) {
  return axios.get(`/api/admin/userChoices/${id}`);
};

apiService.userChoiceDelete = function (id) {
  return axios.delete(`/api/admin/userChoices/${id}`);
};

apiService.changeUserChoiceOrder = function (params) {
  return axios.post("/api/admin/userChoices/indices", params);
};

//seo block
apiService.deleteSeoBlockById = function (id) {
  return axios.delete(`/api/admin/seoBlock?id=${id}`);
};

apiService.createSeoBlock = function (data) {
  return axios.post("/api/admin/seoBlock", data);
};

apiService.createSeoBlockWithTranslations = function (data) {
  return axios.post("/api/admin/seoBlockWithTranslates", data);
};

apiService.createSeoBlockTranslation = function (data) {
  return axios.post("/api/admin/seoBlockTranslation", data);
};

apiService.getAllSeoBlock = function () {
  return axios.get("/api/admin/seoBlock/all");
};

apiService.getAllSeoBlockByPage = function (data) {
  return axios.post("/api/admin/seoBlock/all", data);
};

apiService.getSeoBlockById = function (id) {
  return axios.get(`/api/admin/seoBlock?id=${id}`);
};

apiService.getSeoBlockTranslationById = function (seoBlockId) {
  return axios.get(
    `/api/admin/seoBlockTranslation/all/seoBlockId?seoBlockId=${seoBlockId}`
  );
};

apiService.updateSeoBlock = function (data) {
  return axios.put("/api/admin/seoBlock", data);
};
apiService.updateSeoBlockTranslation = function (data) {
  return axios.put("/api/admin/seoBlockTranslation", data);
};

// tinyMCE keys
apiService.getTinyMCEKeys = function (onlyValid) {
  return axios.get(`/api/admin/tiny${onlyValid ? "?isValid=true" : ""}`);
};

apiService.updateTinyMCEKeys = function (data) {
  return axios.post("/api/admin/tiny", data);
};

//favicon
apiService.extractFavicon = function (siteId) {
  return axios.get(`/api/admin/site/favicon/extract?siteId=${siteId}`);
};

// tools settings
apiService.getToolsAll = function () {
  return axios.get("/api/admin/tools");
};

apiService.createTools = function (data) {
  return axios.post("/api/admin/tools", data);
};

apiService.getToolsById = function (id) {
  return axios.get(`/api/admin/tools/${id}`);
};

apiService.updateTools = function (data) {
  return axios.put("/api/admin/tools", data);
};

apiService.changeIndicesTools = function (data) {
  return axios.put("/api/admin/tools/indices", data);
};

apiService.deleteTools = function (id) {
  return axios.delete(`/api/admin/tools/${id}`);
};

apiService.getSiteStatisticAll = function (params) {
  return axios.get("/api/admin/siteStatistics/groupBySites", { params });
};

apiService.getSiteStatisticById = function (params) {
  return axios.get("/api/siteStatistics/graph", { params });
};

export default apiService;
