import { Button, Dropdown, Menu, message, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
const TinyKeys = ({ data, isLoaded, error }) => {
  const [activeKey, setActiveKey] = useState(
    localStorage.getItem("tinymce_key") || "default"
  );
  const [keys, setKeys] = useState([]);

  const handleklick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("tinymce_key", id);
    setActiveKey(id);
    if (window.tinymce) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (isLoaded) {
      if (error || !data) {
        message.error(
          "Ошибка загрузки ключей для tinyMCE,  будет использован ключ по умолчанию"
        );
        return;
      }
      if (data?.length === 0) {
        localStorage.setItem("tinymce_key", "default");
        return;
      }
      if (!data.find((item) => item.id === activeKey)) {
        localStorage.setItem("tinymce_key", data[0].id);
        setActiveKey(data[0].id);
      }
      setKeys(
        data.map((item, index) => ({ ...item, label: `Ключ ${index + 1}` }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoaded, error]);

  const menu = (
    <Menu>
      {keys?.map((item) => (
        <Menu.Item key={item.id}>
          <a href="/" onClick={(e) => handleklick(e, item.id)}>
            {item.label}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Space style={{ marginRight: "10px" }}>
      <Typography.Text>TinyMCE:</Typography.Text>
      <Dropdown overlay={menu} placement="bottom" trigger={"click"}>
        <Button>
          {keys.find((item) => item.id === activeKey)?.label || "По умолчанию"}
        </Button>
      </Dropdown>
    </Space>
  );
};

const mapStateToProps = ({ tinyKeys }) => {
  const { data, isLoaded, error } = tinyKeys;
  return { data, isLoaded, error };
};

export default connect(mapStateToProps)(TinyKeys);
