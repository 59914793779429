import { ERROR_TINY_KEYS, SET_TINY_KEYS } from "../constants/TinyKeys";

const initState = {
  data: [],
  isLoaded: false,
  error: null,
};

const tinyKeys = (state = initState, action) => {
  switch (action.type) {
    case SET_TINY_KEYS: {
      return {
        ...state,
        data: action.payload,
        isLoaded: true,
        error: null,
      };
    }
    case ERROR_TINY_KEYS: {
      return {
        ...state,
        data: [],
        isLoaded: true,
        error: action.payload,
      };
    }

    default:
      return state;
  }
};

export default tinyKeys;
