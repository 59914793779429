import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  BorderOutlined,
  CreditCardOutlined,
  KeyOutlined,
  LikeOutlined,
  DislikeOutlined,
  MoneyCollectOutlined,
  CheckCircleOutlined,
  HighlightOutlined,
  FormOutlined,
  GlobalOutlined,
  StarOutlined,
  RightOutlined,
  DoubleRightOutlined,
  ZhihuOutlined,
  SettingOutlined,
  PercentageOutlined,
  AreaChartOutlined,
  ClusterOutlined,
  FileSearchOutlined,
  AimOutlined,
  PartitionOutlined,
  DatabaseOutlined,
  SafetyCertificateOutlined,
  SecurityScanOutlined,
  PullRequestOutlined,
  SyncOutlined,
  ShareAltOutlined,
  TranslationOutlined,
  PicLeftOutlined,
  QuestionCircleOutlined,
  SignatureOutlined,
  TableOutlined
} from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";

const content = [
  {
    key: "content",
    path: `${APP_PREFIX_PATH}/content`,
    title: "Content",
    icon: BorderOutlined,
    breadcrumb: true,
    roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
    submenu: [
      {
        key: "sites-main",
        path: `${APP_PREFIX_PATH}/site`,
        title: "Sites",
        icon: LayoutOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [
          {
            key: "sites-list",
            path: `${APP_PREFIX_PATH}/site/list`,
            title: "All",
            icon: CreditCardOutlined,
            breadcrumb: false,
            roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
            submenu: [],
          },
          {
            key: "sites-proxy",
            path: `${APP_PREFIX_PATH}/site/proxy/list`,
            title: "PROXY",
            icon: CreditCardOutlined,
            breadcrumb: false,
            roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
            submenu: [],
          },
        ],
      },
      {
        key: "sites-top",
        path: `${APP_PREFIX_PATH}/site/top`,
        title: "Sites TOP",
        icon: StarOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "sites-validator",
        path: `${APP_PREFIX_PATH}/site/validator`,
        title: "Sites Validator",
        icon: SafetyCertificateOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "sites-whois",
        path: `${APP_PREFIX_PATH}/site/whois`,
        title: "Sites Whois",
        icon: SecurityScanOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "email-validator",
        path: `${APP_PREFIX_PATH}/validator/email`,
        title: "Email Validator",
        icon: MailOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "blog",
        path: `${APP_PREFIX_PATH}/blog/list`,
        title: "Blog",
        icon: HighlightOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "review",
        path: `${APP_PREFIX_PATH}/review/list`,
        title: "Reviews",
        icon: LikeOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
        showCount: true,
      },
      {
        key: "complaints",
        path: `${APP_PREFIX_PATH}/complaints/list`,
        title: "Complaints",
        icon: DislikeOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "comments",
        path: `${APP_PREFIX_PATH}/comments/list`,
        title: "Comments",
        icon: CommentOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
        showCount: true,
      },
      {
        key: "feedback",
        path: `${APP_PREFIX_PATH}/feedback/list`,
        title: "Feedback",
        icon: FormOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
        showCount: true,
      },
      {
        key: "promocode",
        path: `${APP_PREFIX_PATH}/promocode/list`,
        title: "Promo Codes",
        icon: PercentageOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "user-choice",
        path: `${APP_PREFIX_PATH}/user-choice/list`,
        title: "User Choice",
        icon: FileDoneOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "faq",
        path: `${APP_PREFIX_PATH}/faq/list`,
        title: "FAQ",
        icon: QuestionCircleOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },

      {
        key: "seoBlock",
        path: `${APP_PREFIX_PATH}/seo-block/list`,
        title: "SEO block",
        icon: SignatureOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
    ],
  },
];

const settings = [
  {
    key: "settings",
    title: "Settings",
    icon: SettingOutlined,
    breadcrumb: true,
    roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
    submenu: [
      {
        key: "pages-list",
        path: `${APP_PREFIX_PATH}/page/list`,
        title: "Pages",
        icon: FileTextOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "meta-pages",
        path: `${APP_PREFIX_PATH}/meta/list`,
        title: "Meta Pages",
        icon: FileSearchOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "countries-list",
        path: `${APP_PREFIX_PATH}/countries/list`,
        title: "Countries",
        icon: GlobalOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "translation-list",
        path: `${APP_PREFIX_PATH}/translation/list`,
        title: "Translations",
        icon: ZhihuOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "goals-list",
        path: `${APP_PREFIX_PATH}/goal/list`,
        title: "Goals",
        icon: RightOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "goal-groups-list",
        path: `${APP_PREFIX_PATH}/goal/group/list`,
        title: "Goal Groups",
        icon: DoubleRightOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "site-status",
        path: `${APP_PREFIX_PATH}/status/list`,
        title: "Site Statuses",
        icon: CheckCircleOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "currency",
        path: `${APP_PREFIX_PATH}/currency/list`,
        title: "Currencies",
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "rent",
        path: `${APP_PREFIX_PATH}/rent/list`,
        title: "Minimum Rent",
        icon: KeyOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "email-template",
        path: `${APP_PREFIX_PATH}/email/list`,
        title: "Email Templates",
        icon: MailOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "site-statistic",
        path: `${APP_PREFIX_PATH}/statistic/edit`,
        title: "Site Statistics",
        icon: AreaChartOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [
          {
            key: "site-statistic-list",
            path: `${APP_PREFIX_PATH}/statistic/list`,
            title: "List",
            icon: TableOutlined,
            breadcrumb: false,
            roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
            submenu: [],
          },
          // {
          //   key: "site-statistic-chart",
          //   path: `${APP_PREFIX_PATH}/statistic/chart`,
          //   title: "Chart",
          //   icon: BarChartOutlined,
          //   breadcrumb: false,
          //   roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
          //   submenu: [],
          // },
        ],
      },
      {
        key: "proxy-types",
        path: `${APP_PREFIX_PATH}/proxy/type/list`,
        title: "Proxy Types",
        icon: ClusterOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "tools",
        path: `${APP_PREFIX_PATH}/tools/list`,
        title: "Tools Settings",
        icon: ClusterOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "social",
        path: `${APP_PREFIX_PATH}/social/list`,
        title: "Social",
        icon: ShareAltOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "redirect",
        path: `${APP_PREFIX_PATH}/redirect/list`,
        title: "Redirects",
        icon: PullRequestOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "captcha",
        path: `${APP_PREFIX_PATH}/captcha/setting`,
        title: "Captcha",
        icon: SyncOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "tiny-keys",
        path: `${APP_PREFIX_PATH}/tiny-keys/list`,
        title: "Tiny Keys",
        icon: KeyOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "main-blocks-list",
        path: `${APP_PREFIX_PATH}/main/blocks/list`,
        title: "Main Blocks List",
        icon: PicLeftOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "front-error-report",
        path: `${APP_PREFIX_PATH}/front/error/report`,
        title: "Front Error Reports",
        icon: PicLeftOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
    ],
  },
];

const system = [
  {
    key: "system",
    path: `${APP_PREFIX_PATH}/users`,
    title: "System",
    icon: UnorderedListOutlined,
    breadcrumb: true,
    roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
    submenu: [
      {
        key: "users-list",
        path: `${APP_PREFIX_PATH}/users/list`,
        title: "Users",
        icon: TeamOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "properties-page",
        path: `${APP_PREFIX_PATH}/system/properties`,
        title: "Properties",
        icon: UnorderedListOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "seo",
        path: `${APP_PREFIX_PATH}/seo/setting`,
        title: "SEO",
        icon: AimOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "sitemap",
        path: `${APP_PREFIX_PATH}/sitemap/setting`,
        title: "SiteMap",
        icon: PartitionOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "cache",
        path: `${APP_PREFIX_PATH}/cache/setting`,
        title: "Cache",
        icon: DatabaseOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "translator",
        path: `${APP_PREFIX_PATH}/utils/translator`,
        title: "Translator",
        icon: TranslationOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
      {
        key: "externalStorage",
        path: `${APP_PREFIX_PATH}/externalStorage/setting`,
        title: "External storage",
        icon: DatabaseOutlined,
        breadcrumb: false,
        roles: ["SUPERUSER", "ADMIN", "SYS_ADMIN"],
        submenu: [],
      },
    ],
  },
];

const extraNavTree = [
  {
    key: "extra",
    path: `${APP_PREFIX_PATH}/pages`,
    title: "Pages",
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "extra-pages",
        path: `${APP_PREFIX_PATH}/pages`,
        title: "Pages",
        icon: FileTextOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-pages-profile",
            path: `${APP_PREFIX_PATH}/pages/profile`,
            title: "Profile",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "extra-pages-list",
            path: `${APP_PREFIX_PATH}/pages/user-list`,
            title: "User list",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-pages-invoice",
            path: `${APP_PREFIX_PATH}/pages/invoice`,
            title: "Invoice",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-pages-pricing",
            path: `${APP_PREFIX_PATH}/pages/pricing`,
            title: "Pricing",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-pages-faq",
            path: `${APP_PREFIX_PATH}/pages/faq`,
            title: "FAQ",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "extra-pages-setting",
            path: `${APP_PREFIX_PATH}/pages/setting`,
            title: "Setting",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "extra-auth",
        path: `${AUTH_PREFIX_PATH}`,
        title: "Authentication",
        icon: SafetyOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-auth-login-1",
            path: `${AUTH_PREFIX_PATH}/login-1`,
            title: "Login 1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-login-2",
            path: `${AUTH_PREFIX_PATH}/login-2`,
            title: "Login 2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-register-1",
            path: `${AUTH_PREFIX_PATH}/register-1`,
            title: "Register 1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-register-2",
            path: `${AUTH_PREFIX_PATH}/register-2`,
            title: "Register 2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-auth-forgot-password",
            path: `${AUTH_PREFIX_PATH}/forgot-password`,
            title: "Forget Password",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "extra-errors",
        path: `${AUTH_PREFIX_PATH}/error-1`,
        title: "Errors",
        icon: StopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "extra-errors-error-1",
            path: `${AUTH_PREFIX_PATH}/error-1`,
            title: "Error Page 1",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "extra-errors-error-2",
            path: `${AUTH_PREFIX_PATH}/error-2`,
            title: "Error Page 2",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "Dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        title: "Default",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-analytic",
        path: `${APP_PREFIX_PATH}/dashboards/analytic`,
        title: "Analytic",
        icon: DotChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "dashboards-sales",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "Sales",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const appsNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "Apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-mail",
        path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
        title: "Mail",
        icon: MailOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-chat",
        path: `${APP_PREFIX_PATH}/apps/chat`,
        title: "Chat",
        icon: MessageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-calendar",
        path: `${APP_PREFIX_PATH}/apps/calendar`,
        title: "Calendar",
        icon: CalendarOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-project",
        path: `${APP_PREFIX_PATH}/apps/project`,
        title: "Project",
        icon: BulbOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-project-list",
            path: `${APP_PREFIX_PATH}/apps/project/list`,
            title: "List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-project-scrumboard",
            path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
            title: "Scrumboard",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "apps-ecommerce",
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        title: "E-Commerce",
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "apps-ecommerce-productList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/product-list`,
            title: "Product List",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "apps-ecommerce-addProduct",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/add-product`,
            title: "Add Product",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-editProduct",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/12`,
            title: "Edit Product",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-orders",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
            title: "Orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const componentsNavTree = [
  {
    key: "components",
    path: `${APP_PREFIX_PATH}/components`,
    title: "Components",
    icon: AntDesignOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: "components-general",
        path: `${APP_PREFIX_PATH}/components/general`,
        title: "General",
        icon: InfoCircleOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-general-button",
            path: `${APP_PREFIX_PATH}/components/general/button`,
            title: "Button",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-general-icon",
            path: `${APP_PREFIX_PATH}/components/general/icon`,
            title: "Icon",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-general-typography",
            path: `${APP_PREFIX_PATH}/components/general/typography`,
            title: "Typography",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-layout",
        path: `${APP_PREFIX_PATH}/components/layout`,
        title: "Layout",
        icon: LayoutOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-layout-grid",
            path: `${APP_PREFIX_PATH}/components/layout/grid`,
            title: "Grid",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-layout-layout",
            path: `${APP_PREFIX_PATH}/components/layout/layout`,
            title: "Layout",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-navigation",
        path: `${APP_PREFIX_PATH}/components/navigation`,
        title: "Navigation",
        icon: CompassOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-navigation-affix",
            path: `${APP_PREFIX_PATH}/components/navigation/affix`,
            title: "Affix",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-breadcrumb",
            path: `${APP_PREFIX_PATH}/components/navigation/breadcrumb`,
            title: "Breadcrumb",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-dropdown",
            path: `${APP_PREFIX_PATH}/components/navigation/dropdown`,
            title: "Dropdown",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-menu",
            path: `${APP_PREFIX_PATH}/components/navigation/menu`,
            title: "Menu",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-pagination",
            path: `${APP_PREFIX_PATH}/components/navigation/pagination`,
            title: "Pagination",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-page-header",
            path: `${APP_PREFIX_PATH}/components/navigation/page-header`,
            title: "Page Header",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-navigation-steps",
            path: `${APP_PREFIX_PATH}/components/navigation/steps`,
            title: "Steps",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-data-entry",
        path: `${APP_PREFIX_PATH}/components/data-entry`,
        title: "Data Entry",
        icon: FileDoneOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-data-entry-auto-complete",
            path: `${APP_PREFIX_PATH}/components/data-entry/auto-complete`,
            title: "Auto Complete",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-checkbox",
            path: `${APP_PREFIX_PATH}/components/data-entry/checkbox`,
            title: "Checkbox",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-cascader",
            path: `${APP_PREFIX_PATH}/components/data-entry/cascader`,
            title: "Cascader",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-date-picker",
            path: `${APP_PREFIX_PATH}/components/data-entry/date-picker`,
            title: "Date Picker",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-form",
            path: `${APP_PREFIX_PATH}/components/data-entry/form`,
            title: "Form",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-input-number",
            path: `${APP_PREFIX_PATH}/components/data-entry/input-number`,
            title: "Input Number",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-input",
            path: `${APP_PREFIX_PATH}/components/data-entry/input`,
            title: "Input",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-mentions",
            path: `${APP_PREFIX_PATH}/components/data-entry/mentions`,
            title: "Mentions",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-rate",
            path: `${APP_PREFIX_PATH}/components/data-entry/rate`,
            title: "Rate",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-radio",
            path: `${APP_PREFIX_PATH}/components/data-entry/radio`,
            title: "Radio",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-switch",
            path: `${APP_PREFIX_PATH}/components/data-entry/switch`,
            title: "Switch",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-slider",
            path: `${APP_PREFIX_PATH}/components/data-entry/slider`,
            title: "Slider",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-select",
            path: `${APP_PREFIX_PATH}/components/data-entry/select`,
            title: "Select",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-tree-select",
            path: `${APP_PREFIX_PATH}/components/data-entry/tree-select`,
            title: "Tree Select",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-transfer",
            path: `${APP_PREFIX_PATH}/components/data-entry/transfer`,
            title: "Transfer",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-time-picker",
            path: `${APP_PREFIX_PATH}/components/data-entry/time-picker`,
            title: "Time Picker",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-entry-upload",
            path: `${APP_PREFIX_PATH}/components/data-entry/upload`,
            title: "Upload",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-data-display",
        path: `${APP_PREFIX_PATH}/components/data-display`,
        title: "Data Display",
        icon: DesktopOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-data-display-avatar",
            path: `${APP_PREFIX_PATH}/components/data-display/avatar`,
            title: "Avatar",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-badge",
            path: `${APP_PREFIX_PATH}/components/data-display/badge`,
            title: "Badge",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-comment",
            path: `${APP_PREFIX_PATH}/components/data-display/comment`,
            title: "Comment",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-collapse",
            path: `${APP_PREFIX_PATH}/components/data-display/collapse`,
            title: "Collapse",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-carousel",
            path: `${APP_PREFIX_PATH}/components/data-display/carousel`,
            title: "Carousel",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-card",
            path: `${APP_PREFIX_PATH}/components/data-display/card`,
            title: "Card",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-calendar",
            path: `${APP_PREFIX_PATH}/components/data-display/calendar`,
            title: "Calendar",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-descriptions",
            path: `${APP_PREFIX_PATH}/components/data-display/descriptions`,
            title: "Descriptions",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-empty",
            path: `${APP_PREFIX_PATH}/components/data-display/empty`,
            title: "Empty",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-list",
            path: `${APP_PREFIX_PATH}/components/data-display/list`,
            title: "List",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-popover",
            path: `${APP_PREFIX_PATH}/components/data-display/popover`,
            title: "Popover",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-statistic",
            path: `${APP_PREFIX_PATH}/components/data-display/statistic`,
            title: "Statistic",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tree",
            path: `${APP_PREFIX_PATH}/components/data-display/tree`,
            title: "Tree",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tooltip",
            path: `${APP_PREFIX_PATH}/components/data-display/tooltip`,
            title: "Tooltip",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-timeline",
            path: `${APP_PREFIX_PATH}/components/data-display/timeline`,
            title: "Timeline",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tag",
            path: `${APP_PREFIX_PATH}/components/data-display/tag`,
            title: "Tag",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-tabs",
            path: `${APP_PREFIX_PATH}/components/data-display/tabs`,
            title: "Tabs",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-data-display-table",
            path: `${APP_PREFIX_PATH}/components/data-display/table`,
            title: "Table",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-feedback",
        path: `${APP_PREFIX_PATH}/components/feedback`,
        title: "Feedback",
        icon: CommentOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-feedback-alert",
            path: `${APP_PREFIX_PATH}/components/feedback/alert`,
            title: "Alert",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-drawer",
            path: `${APP_PREFIX_PATH}/components/feedback/drawer`,
            title: "Drawer",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-modal",
            path: `${APP_PREFIX_PATH}/components/feedback/modal`,
            title: "Modal",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-message",
            path: `${APP_PREFIX_PATH}/components/feedback/message`,
            title: "Message",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-notification",
            path: `${APP_PREFIX_PATH}/components/feedback/notification`,
            title: "Notification",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-progress",
            path: `${APP_PREFIX_PATH}/components/feedback/progress`,
            title: "Progress",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-popconfirm",
            path: `${APP_PREFIX_PATH}/components/feedback/popconfirm`,
            title: "Popconfirm",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-result",
            path: `${APP_PREFIX_PATH}/components/feedback/result`,
            title: "Result",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-spin",
            path: `${APP_PREFIX_PATH}/components/feedback/spin`,
            title: "Spin",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-feedback-skeleton",
            path: `${APP_PREFIX_PATH}/components/feedback/skeleton`,
            title: "Skeleton",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-other",
        path: `${APP_PREFIX_PATH}/components/other`,
        title: "Other",
        icon: RobotOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-other-anchor",
            path: `${APP_PREFIX_PATH}/components/other/anchor`,
            title: "Anchor",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-other-backtop",
            path: `${APP_PREFIX_PATH}/components/other/backtop`,
            title: "BackTop",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-other-config-provider",
            path: `${APP_PREFIX_PATH}/components/other/config-provider`,
            title: "Config Provider",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-other-divider",
            path: `${APP_PREFIX_PATH}/components/other/divider`,
            title: "Divider",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-charts",
        path: `${APP_PREFIX_PATH}/components/charts`,
        title: "Charts",
        icon: PieChartOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-charts-apex",
            path: `${APP_PREFIX_PATH}/components/charts/apex-charts`,
            title: "Apex Charts",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-charts-chartjs",
            path: `${APP_PREFIX_PATH}/components/charts/chartjs`,
            title: "ChartJs",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: "components-maps",
        path: `${APP_PREFIX_PATH}/components/maps`,
        title: "Maps",
        icon: EnvironmentOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "components-maps-google",
            path: `${APP_PREFIX_PATH}/components/maps/google-map`,
            title: "Google Maps",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "components-maps-simple",
            path: `${APP_PREFIX_PATH}/components/maps/simple-map`,
            title: "Simple Maps",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const docsNavTree = [
  {
    key: "docs",
    path: `${APP_PREFIX_PATH}/docs`,
    title: "Docs",
    icon: BookOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "docs-documentation",
        path: `${APP_PREFIX_PATH}/docs/documentation`,
        title: "Documentation",
        icon: FileUnknownOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "docs-changelog",
        path: `${APP_PREFIX_PATH}/docs/documentation/changelog`,
        title: "Changelog",
        icon: ProfileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...content, ...settings, ...system];

if (process.env.NODE_ENV === "development") {
  navigationConfig.push(
    ...dashBoardNavTree,
    ...appsNavTree,
    ...componentsNavTree,
    ...extraNavTree,
    ...docsNavTree
  );
}

export default navigationConfig;
