import { all, takeEvery, put, fork, call } from "redux-saga/effects";

import apiService from "../../services/ApiService";
import { GET_TINY_KEYS } from "../constants/TinyKeys";
import { errorTinyKeys, setTinyKeys } from "../actions/TinyKeys";

export function* getTinyKeysSaga() {
  yield takeEvery(GET_TINY_KEYS, function* () {
    try {
      const response = yield call(apiService.getTinyMCEKeys, true);
      if (response?.status === 200) {
        yield put(setTinyKeys(response?.data));
      } else {
        throw new Error("Ошибка загрузки tinyKeys.");
      }
    } catch (err) {
      yield put(errorTinyKeys(err?.massage || err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getTinyKeysSaga)]);
}
