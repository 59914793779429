import {
  ERROR_COUNT_COMMENT,
  ERROR_COUNT_FEEDBACK,
  ERROR_COUNT_REVIEW,
  GET_COUNT_COMMENT,
  GET_COUNT_FEEDBACK,
  GET_COUNT_REVIEW,
  SET_COUNT_COMMENT,
  SET_COUNT_FEEDBACK,
  SET_COUNT_REVIEW,
} from "../constants/Counts";

export const getCountComment = () => {
  return {
    type: GET_COUNT_COMMENT,
  };
};

export const setCountComment = (count) => {
  return {
    type: SET_COUNT_COMMENT,
    payload: count,
  };
};

export const errorCountComment = (error) => {
  return {
    type: ERROR_COUNT_COMMENT,
    payload: error,
  };
};

export const getCountReview = () => {
  return {
    type: GET_COUNT_REVIEW,
  };
};

export const setCountReview = (count) => {
  return {
    type: SET_COUNT_REVIEW,
    payload: count,
  };
};

export const errorCountReview = (error) => {
  return {
    type: ERROR_COUNT_REVIEW,
    payload: error,
  };
};

export const getCountFeedback = () => {
  return {
    type: GET_COUNT_FEEDBACK,
  };
};

export const setCountFeedback = (count) => {
  return {
    type: SET_COUNT_FEEDBACK,
    payload: count,
  };
};

export const errorCountFeedback = (error) => {
  return {
    type: ERROR_COUNT_FEEDBACK,
    payload: error,
  };
};
