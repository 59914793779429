import {
  SET_COUNT_COMMENT,
  ERROR_COUNT_COMMENT,
  SET_COUNT_REVIEW,
  ERROR_COUNT_REVIEW,
  ERROR_COUNT_FEEDBACK,
  SET_COUNT_FEEDBACK,
} from "../constants/Counts";

const initState = {
  comments: {
    isLoading: false,
    count: 0,
    error: null,
  },
  review: {
    isLoading: false,
    count: 0,
    error: null,
  },
  feedback: {
    isLoading: false,
    count: 0,
    error: null,
  },
};

const counts = (state = initState, action) => {
  switch (action.type) {
    case SET_COUNT_COMMENT: {
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          count: action.payload,
        },
      };
    }
    case ERROR_COUNT_COMMENT: {
      return {
        ...state,
        comments: {
          ...state.comments,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case SET_COUNT_REVIEW: {
      return {
        ...state,
        review: {
          ...state.review,
          isLoading: false,
          count: action.payload,
        },
      };
    }
    case ERROR_COUNT_REVIEW: {
      return {
        ...state,
        review: {
          ...state.review,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    case SET_COUNT_FEEDBACK: {
      return {
        ...state,
        feedback: {
          ...state.feedback,
          isLoading: false,
          count: action.payload,
        },
      };
    }
    case ERROR_COUNT_FEEDBACK: {
      return {
        ...state,
        feedback: {
          ...state.feedback,
          isLoading: false,
          error: action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default counts;
