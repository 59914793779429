import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Counts from "./Counts";
import TinyKeys from "./TinyKeys";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  counts: Counts,
  tinyKeys: TinyKeys,
});

export default reducers;
